.account-screen {
	width: 100%;
	max-width: 800px;
	display: grid;
	grid-template-columns: 200px auto;
}

.new-integration-link {
	padding: 15px;
	margin: 10px 0;
	border-radius: 5px;
	border: #666 1px solid;
	text-decoration: none;
	font-weight: 600;
	font-size: 14pt;
	color: black;
	background-color: rgb(211, 211, 211);
	display: block;
}

.account-details {
	left: 10%;

	.header {
		text-decoration: underline;
		font-size: 15pt;
	}
}

.integrations {
	.header {
		text-decoration: underline;
		font-size: 15pt;
	}

	.integration-item {
		margin: 10px 0;
		padding: 0 10px;
		border: solid 1px black;
	}
	form {
		display: block;

		label {
			padding: 10px 5px;
		}

		fieldset {
			border: none;
		}
	}
}
