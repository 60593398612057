$inputPadding: 6px;

form.signup {
	width: 400px;
	min-width: 300px;

	input,
	select {
		height: 30px;
		border: #666 1px solid;
		border-radius: 3px;
	}

	select {
		width: 100%;
	}

	input {
		width: calc(100% - #{$inputPadding + 3});
		padding-left: $inputPadding;
	}

	label {
		width: 100%;
		text-align: left;
		display: block;
		margin-top: 7px;
		margin-bottom: 2px;
	}

	input[type="submit"] {
		padding: 5px 20px;
		margin: 10px 0;
		border-radius: 5px;
		border: #666 1px solid;
		cursor: auto;
	}

	input[type="submit"]:disabled {
		border: #ccc 1px solid;
		cursor: not-allowed;
	}

	fieldset {
		padding: 3px 15px;
		border-radius: 5px;
		border: #666 1px solid;
	}
}
